import { useState, useEffect, useRef } from "react";
import "../../resources/Keno/css/the_game.css";
import videoBg from "../../resources/Keno/img/keno_video.mp4";
//import img_bottle from "../resources/img/bottle_transparent.png";
const draw_img = require("../../resources/Keno/img/time-removebg-preview.png")
const draw_img1 = require("../../resources/Keno/img/draw_after.png")
const draw_img2 = require("../../resources/Keno/img/draw_after.png")
const draw_img3 = require("../../resources/Keno/img/draw_clock.png")
const img_bottle = require("../../resources/Keno/img/bottle_transparent.png");
const keno_logo = require("../../resources/Keno/img/keno_logo.png");
// const wooden_shelves = require('../resources/img/wooden_shelves.png');
const one_img = require("../../resources/Keno/img/1.png");
const two_img = require("../../resources/Keno/img/2.png");
const three_img = require("../../resources/Keno/img/3.png");
const four_img = require("../../resources/Keno/img/4.png");
const five_img = require("../../resources/Keno/img/5.png");
const six_img = require("../../resources/Keno/img/6.png");
const seven_img = require("../../resources/Keno/img/7.png");
const eight_img = require("../../resources/Keno/img/8.png");
const nine_img = require("../../resources/Keno/img/9.png");
const ten_img = require("../../resources/Keno/img/10.png");
const eleven_img = require("../../resources/Keno/img/11.png");
const twelve_img = require("../../resources/Keno/img/12.png");
const thirteen_img = require("../../resources/Keno/img/13.png");
const fourteen_img = require("../../resources/Keno/img/14.png");
const fifteen_img = require("../../resources/Keno/img/15.png");
const sixteen_img = require("../../resources/Keno/img/16.png");
const seventeen_img = require("../../resources/Keno/img/17.png");
const eighteen_img = require("../../resources/Keno/img/18.png");
const nineteen_img = require("../../resources/Keno/img/19.png");
const twenty_img = require("../../resources/Keno/img/20.png");
const twentyone_img = require("../../resources/Keno/img/21.png");
const twentytwo_img = require("../../resources/Keno/img/22.png");
const twentythree_img = require("../../resources/Keno/img/23.png");
const twentyfour_img = require("../../resources/Keno/img/24.png");
const twentyfive_img = require("../../resources/Keno/img/25.png");
const twentysix_img = require("../../resources/Keno/img/26.png");
const twentyseven_img = require("../../resources/Keno/img/27.png");
const twentyeight_img = require("../../resources/Keno/img/28.png");
const twentynine_img = require("../../resources/Keno/img/29.png");
const thirty_img = require("../../resources/Keno/img/30.png");
const thirtyone_img = require("../../resources/Keno/img/31.png");
const thirtytwo_img = require("../../resources/Keno/img/32.png");
const thirtythree_img = require("../../resources/Keno/img/33.png");
const thirtyfour_img = require("../../resources/Keno/img/34.png");
const thirtyfive_img = require("../../resources/Keno/img/35.png");
const thirtysix_img = require("../../resources/Keno/img/36.png");
const thirtyseven_img = require("../../resources/Keno/img/37.png");
const thirtyeight_img = require("../../resources/Keno/img/38.png");
const thirtynine_img = require("../../resources/Keno/img/39.png");
const fourty_img = require("../../resources/Keno/img/40.png");
const fourtyone_img = require("../../resources/Keno/img/41.png");
const fourtytwo_img = require("../../resources/Keno/img/42.png");
const fourtythree_img = require("../../resources/Keno/img/43.png");
const fourtyfour_img = require("../../resources/Keno/img/44.png");
const fourtyfive_img = require("../../resources/Keno/img/45.png");
const fourtysix_img = require("../../resources/Keno/img/46.png");
const fourtyseven_img = require("../../resources/Keno/img/47.png");
const fourtyeight_img = require("../../resources/Keno/img/48.png");
const fourtynine_img = require("../../resources/Keno/img/49.png");
const fifty_img = require("../../resources/Keno/img/50.png");
const fiftyone_img = require("../../resources/Keno/img/51.png");
const fiftytwo_img = require("../../resources/Keno/img/52.png");
const fiftythree_img = require("../../resources/Keno/img/53.png");
const fiftyfour_img = require("../../resources/Keno/img/54.png");
const fiftyfive_img = require("../../resources/Keno/img/55.png");
const fiftysix_img = require("../../resources/Keno/img/56.png");
const fiftyseven_img = require("../../resources/Keno/img/57.png");
const fiftyeight_img = require("../../resources/Keno/img/58.png");
const fiftynine_img = require("../../resources/Keno/img/59.png");
const sixty_img = require("../../resources/Keno/img/60.png");
const sixtyone_img = require("../../resources/Keno/img/61.png");
const sixtytwo_img = require("../../resources/Keno/img/62.png");
const sixtythree_img = require("../../resources/Keno/img/63.png");
const sixtyfour_img = require("../../resources/Keno/img/64.png");
const sixtyfive_img = require("../../resources/Keno/img/65.png");
const sixtysix_img = require("../../resources/Keno/img/66.png");
const sixtyseven_img = require("../../resources/Keno/img/67.png");
const sixtyeight_img = require("../../resources/Keno/img/68.png");
const sixtynine_img = require("../../resources/Keno/img/69.png");
const seventy_img = require("../../resources/Keno/img/70.png");
const seventyone_img = require("../../resources/Keno/img/71.png");
const seventytwo_img = require("../../resources/Keno/img/72.png");
const seventythree_img = require("../../resources/Keno/img/73.png");
const seventyfour_img = require("../../resources/Keno/img/74.png");
const seventyfive_img = require("../../resources/Keno/img/75.png");
const seventysix_img = require("../../resources/Keno/img/76.png");
const seventyseven_img = require("../../resources/Keno/img/77.png");
const seventyeight_img = require("../../resources/Keno/img/78.png");
const seventynine_img = require("../../resources/Keno/img/79.png");
const eighty_img = require("../../resources/Keno/img/80.png");


const { base_url } = require("../../constants/index.js")

const imgs = [];
imgs[1] = one_img;
imgs[2] = two_img;
imgs[3] = three_img;
imgs[4] = four_img;
imgs[5] = five_img;
imgs[6] = six_img;
imgs[7] = seven_img;
imgs[8] = eight_img;
imgs[9] = nine_img;
imgs[10] = ten_img;
imgs[11] = eleven_img;
imgs[12] = twelve_img;
imgs[13] = thirteen_img;
imgs[14] = fourteen_img;
imgs[15] = fifteen_img;
imgs[16] = sixteen_img;
imgs[17] = seventeen_img;
imgs[18] = eighteen_img;
imgs[19] = nineteen_img;
imgs[20] = twenty_img;
imgs[21] = twentyone_img;
imgs[22] = twentytwo_img;
imgs[23] = twentythree_img;
imgs[24] = twentyfour_img;
imgs[25] = twentyfive_img;
imgs[26] = twentysix_img;
imgs[27] = twentyseven_img;
imgs[28] = twentyeight_img;
imgs[29] = twentynine_img;
imgs[30] = thirty_img;
imgs[31] = thirtyone_img;
imgs[32] = thirtytwo_img;
imgs[33] = thirtythree_img;
imgs[34] = thirtyfour_img;
imgs[35] = thirtyfive_img;
imgs[36] = thirtysix_img;
imgs[37] = thirtyseven_img;
imgs[38] = thirtyeight_img;
imgs[39] = thirtynine_img;
imgs[40] = fourty_img;
imgs[41] = fourtyone_img;
imgs[42] = fourtytwo_img;
imgs[43] = fourtythree_img;
imgs[44] = fourtyfour_img;
imgs[45] = fourtyfive_img;
imgs[46] = fourtysix_img;
imgs[47] = fourtyseven_img;
imgs[48] = fourtyeight_img;
imgs[49] = fourtynine_img;
imgs[50] = fifty_img;
imgs[51] = fiftyone_img;
imgs[52] = fiftytwo_img;
imgs[53] = fiftythree_img;
imgs[54] = fiftyfour_img;
imgs[55] = fiftyfive_img;
imgs[56] = fiftysix_img;
imgs[57] = fiftyseven_img;
imgs[58] = fiftyeight_img;
imgs[59] = fiftynine_img;
imgs[60] = sixty_img;
imgs[61] = sixtyone_img;
imgs[62] = sixtytwo_img;
imgs[63] = sixtythree_img;
imgs[64] = sixtyfour_img;
imgs[65] = sixtyfive_img;
imgs[66] = sixtysix_img;
imgs[67] = sixtyseven_img;
imgs[68] = sixtyeight_img;
imgs[69] = sixtynine_img;
imgs[70] = seventy_img;
imgs[71] = seventyone_img;
imgs[72] = seventytwo_img;
imgs[73] = seventythree_img;
imgs[74] = seventyfour_img;
imgs[75] = seventyfive_img;
imgs[76] = seventysix_img;
imgs[77] = seventyseven_img;
imgs[78] = seventyeight_img;
imgs[79] = seventynine_img;
imgs[80] = eighty_img;

var openStatus = "closed";
var current_game_length = "300000";
var video_timer = 0;

const TheGame = () => {
    const audioRefClassical = useRef(null);
    const audioRef1 = useRef(null);
    const audioRef2 = useRef(null);
    const audioRef3 = useRef(null);
    const audioRef4 = useRef(null);
    const audioRef5 = useRef(null);
    const audioRef6 = useRef(null);
    const audioRef7 = useRef(null);
    const audioRef8 = useRef(null);
    const audioRef9 = useRef(null);
    const audioRef10 = useRef(null);
    const audioRef11 = useRef(null);
    const audioRef12 = useRef(null);
    const audioRef13 = useRef(null);
    const audioRef14 = useRef(null);
    const audioRef15 = useRef(null);
    const audioRef16 = useRef(null);
    const audioRef17 = useRef(null);
    const audioRef18 = useRef(null);
    const audioRef19 = useRef(null);
    const audioRef20 = useRef(null);
    const audioRef21 = useRef(null);
    const audioRef22 = useRef(null);
    const audioRef23 = useRef(null);
    const audioRef24 = useRef(null);

    const [slideIn, setSlideIn] = useState(false);
    const [jackPotIn, setJackPotIn] = useState(false);
    const [transitionEnd, setTransitionEnd] = useState(false);
    const [justOpened, setJustOpened] = useState(false);

    const [blankCenterNumber, setBlankCenterNumber] = useState(true);

    const [animationClass, setAnimationClass] = useState(false);
    const [pageState, setPageState] = useState("normal"); // normal, video, animation
    const [animationDelays, setAnimationDelays] = useState([
        "1s",
        "4s",
        "7s",
        "10s",
        "13s",
        "16s",
        "19s",
        "22s",
        "25s",
        "28s",
        "31s",
        "34s",
        "37s",
        "40s",
        "43s",
        "46s",
        "49s",
        "52s",
        "55s",
        "58s",
    ]);
    const [gridClasses, setGridClasses] = useState([
        "dv_grid_cells_normal dv_grid_cells_animated1",
        "dv_grid_cells_normal dv_grid_cells_animated2",
        "dv_grid_cells_normal dv_grid_cells_animated3",
        "dv_grid_cells_normal dv_grid_cells_animated4",
        "dv_grid_cells_normal dv_grid_cells_animated5",
        "dv_grid_cells_normal dv_grid_cells_animated6",
        "dv_grid_cells_normal dv_grid_cells_animated7",
        "dv_grid_cells_normal dv_grid_cells_animated8",
        "dv_grid_cells_normal dv_grid_cells_animated9",
        "dv_grid_cells_normal dv_grid_cells_animated10",
        "dv_grid_cells_normal dv_grid_cells_animated11",
        "dv_grid_cells_normal dv_grid_cells_animated12",
        "dv_grid_cells_normal dv_grid_cells_animated13",
        "dv_grid_cells_normal dv_grid_cells_animated14",
        "dv_grid_cells_normal dv_grid_cells_animated15",
        "dv_grid_cells_normal dv_grid_cells_animated16",
        "dv_grid_cells_normal dv_grid_cells_animated17",
        "dv_grid_cells_normal dv_grid_cells_animated18",
        "dv_grid_cells_normal dv_grid_cells_animated19",
        "dv_grid_cells_normal dv_grid_cells_animated20",
    ]);

    const [grayGridClasses, setGrayGridClasses] = useState([
        "dv_grid_cells_normal dv_grid_gray_cells_animated1",
        "dv_grid_cells_normal dv_grid_gray_cells_animated2",
        "dv_grid_cells_normal dv_grid_gray_cells_animated3",
        "dv_grid_cells_normal dv_grid_gray_cells_animated4",
        "dv_grid_cells_normal dv_grid_gray_cells_animated5",
        "dv_grid_cells_normal dv_grid_gray_cells_animated6",
        "dv_grid_cells_normal dv_grid_gray_cells_animated7",
        "dv_grid_cells_normal dv_grid_gray_cells_animated8",
        "dv_grid_cells_normal dv_grid_gray_cells_animated9",
        "dv_grid_cells_normal dv_grid_gray_cells_animated10",
        "dv_grid_cells_normal dv_grid_gray_cells_animated11",
        "dv_grid_cells_normal dv_grid_gray_cells_animated12",
        "dv_grid_cells_normal dv_grid_gray_cells_animated13",
        "dv_grid_cells_normal dv_grid_gray_cells_animated14",
        "dv_grid_cells_normal dv_grid_gray_cells_animated15",
        "dv_grid_cells_normal dv_grid_gray_cells_animated16",
        "dv_grid_cells_normal dv_grid_gray_cells_animated17",
        "dv_grid_cells_normal dv_grid_gray_cells_animated18",
        "dv_grid_cells_normal dv_grid_gray_cells_animated19",
        "dv_grid_cells_normal dv_grid_gray_cells_animated20",
    ]);

    const [timeRemaining, setTimeRemaining] = useState("00:00");
    const [showBetClosed, setShowBetClosed] = useState(false);
    const [currentGameLength, setCurrentGameLength] = useState(30000);
    const [currentGameId, setCurrentGameId] = useState("NONE");
    const [prevGameId, setPrevGameId] = useState("NONE");
    const [evens, setEvens] = useState("");
    const [outOfTwenty, setOutOfTwenty] = useState("");

    const [lastTenDraws, setLastTenDraws] = useState([]);
    const [lastTenWinners, setLastTenWinners] = useState([]);
    const [lastTenWinnersFirstHalf, setLastTenWinnersFirstHalf] = useState([]);
    const [lastTenWinnersSecondHalf, setLastTenWinnersSecondHalf] = useState([]);

    const [currentNumber, setCurrentNumber] = useState(0);
    const [gridNumberArray, setGridNumberArray] = useState([]);
    const [firstNumber, setFirstNumber] = useState(1);
    const [secondNumber, setSecondNumber] = useState(2);
    const [thirdNumber, setThirdNumber] = useState(3);
    const [fourthNumber, setFourthNumber] = useState(4);
    const [fifthNumber, setFifthNumber] = useState(5);
    const [sixthNumber, setSixthNumber] = useState(6);
    const [seventhNumber, setSeventhNumber] = useState(7);
    const [eighthNumber, setEighthNumber] = useState(8);
    const [ninethNumber, setNinethNumber] = useState(9);
    const [tenthNumber, setTenthNumber] = useState(10);
    const [eleventhNumber, setEleventhNumber] = useState(11);
    const [twelvethNumber, setTwelvethNumber] = useState(12);
    const [thirteenthNumber, setThirteenthNumber] = useState(13);
    const [fourteenthNumber, setFourteenthNumber] = useState(14);
    const [fifteenthNumber, setFifteenthNumber] = useState(15);
    const [sixteenthNumber, setSixteenthNumber] = useState(16);
    const [seventeenthNumber, setSeventeenthNumber] = useState(17);
    const [eighteenthNumber, setEighteenthNumber] = useState(18);
    const [nineteenthNumber, setNineteenthNumber] = useState(19);
    const [twentiethNumber, setTwentiethNumber] = useState(20);
    const [shuffledNumbers, setShuffledNumbers] = useState([]);
    const [audioNumber, setAudioNumber] = useState(1);

    var first_number = 1;
    var second_number = 2;
    var third_number = 3;
    var fourth_number = 4;
    var fifth_number = 5;
    var sixth_number = 6;
    var seventh_number = 7;
    var eighth_number = 8;
    var nineth_number = 9;
    var tenth_number = 10;
    var eleventh_number = 11;
    var twelveth_number = 12;
    var thirteenth_number = 13;
    var fourteenth_number = 14;
    var fifteenth_number = 15;
    var sixteenth_number = 16;
    var seventeenth_number = 17;
    var eighteenth_number = 18;
    var nineteenth_number = 19;
    var twentieth_number = 20;

    const [circleTopNumber, setCircleTopNumber] = useState("");
    const [circleBottomNumber, setCircleBottomNumber] = useState("");
    const [firstTime, setFirstTime] = useState(true);
    const [pickRenderTime, setPickRenderTime] = useState(1);
    const [shuffle_array_state, setShuffleArrayState] = useState([]);

    function get_current_game_length() {
        const url = `${base_url}getgamelength`;
        const res = fetch(url, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        //  console.log(json);
                        setCurrentGameLength(json.game_length);
                        //  setFetchedData(json);
                        current_game_length = json.game_length;
                    });
                } else {
                    // console.log("not fetched");
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //     console.log("not fetched 2");
            });
    }

    function last_ten_rows() {
        const last_ten = [];
        const last_ten_winners = [];
        setLastTenDraws([]);
        setLastTenWinners([]);
        const url_one_twenty = `${base_url}all_tables/users/lasttenrows`;

        const res = fetch(url_one_twenty)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        for (var j = 0; j < json.length; j++) {
                            //  last_ten.push(json[j].winner_number);
                            var winner_numbers_array = [];
                            // console.log(json[j].game_number);
                            //  setLastTenDraws(lastTenDraws => [...lastTenDraws, json[j].game_number]);
                            // winner_numbers_array = shuffle(json[j].winner_numbers.split(","));
                            // setLastTenWinners(lastTenWinners => [...lastTenWinners, winner_numbers_array]);
                            // var game_num = json[j].game_number;
                            // setLastTenDraws(lastTenDraws => [...lastTenDraws, game_num]);
                            last_ten.push(json[j].game_number);
                            // winner_numbers_array = shuffle(json[j].winner_numbers.split(","));
                            winner_numbers_array = json[j].winner_numbers.split(",");
                            last_ten_winners.push(winner_numbers_array);
                        }
                        setLastTenDraws(last_ten);

                        let sorted_games = []
                        for (let index = 0; index < last_ten_winners.length; index++) {
                            const element = last_ten_winners[index].map((e) => parseInt(e)).sort((a, b) => {
                                if (a < b) return -1;
                                else if (a > b) return 1;
                                return 0;
                            });
                            sorted_games.push(element)
                        }
                        setLastTenWinners(sorted_games);
                        console.log('sorted_games');
                        console.log(sorted_games);
                        //   setLastTenNumbers(last_ten);
                        //  console.log(last_ten);
                        // console.log(one_twenty_array);
                        // console.log('fetched');
                    });
                } else {
                    console.log("not fetched");
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                console.log("not fetched 2");
            });
    }

    function fetch_data_intro() {
        //----------------------------fetching open status and time remaining---------------------------
        // const url_open_status = `${base_url}all_tables/users/getopenstatus`;
        const url_open_status = `${base_url}getopenstatus`;

        const res = fetch(url_open_status)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        // console.log(json);
                        if (json.open_status == "closed") {
                            setTimeRemaining("00:00");

                            //setSlideIn(false);

                            //end of start spinner
                            openStatus = "closed";

                            //  console.log("to fetch data intro");
                            fetch_data_intro();
                        } else {
                            //means open

                            setTimeRemaining(json.time_remaining);
                            //openStatus = "open";

                            // console.log("to fetch data first");
                            fetch_data_first();
                        }

                        // console.log("fetched");
                    });
                } else {
                    // console.log("not fetched");
                    fetch_data_intro();
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //     console.log("not fetched 2");
                fetch_data_intro();
            });
        //---------------------------end of fetching open status and time remaining---------------------
    }
    //the timer to fetch data one
    function fetch_data_first() {
        //----------------------------fetching open status and time remaining---------------------------
        // const url_open_status = `${base_url}all_tables/users/getopenstatus`;
        const url_open_status = `${base_url}getopenstatus`;

        const res = fetch(url_open_status)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        //   console.log(json);
                        if (json.open_status == "closed") {
                            setTimeRemaining("00:00");
                            // start spinner
                            if (openStatus != "closed") {
                                console.log("closed changed");
                                //setCurrentNumber(json.winner_number);
                                openStatus = "closed";
                                //   startSpin(json.winner_number, json.random_number);
                                //   setTwentyNumbers(json.winner_number);
                                //   setSlideIn(true);
                                //     console.log(json.winner_numbers);
                                setSlideIn(false);
                                const winner_array = json.winner_numbers.split(",");
                                const shuffle_array = shuffle(winner_array);
                                slide_in(shuffle_array);
                                setPageState("video");
                                //setPageState("animation");
                                video_timer = 0;
                                start_vid_timer(shuffle_array);
                                console.log("here2");

                                //---------------this is where we slide down the numbers-------------------------------------------------

                                //  fetch_data_first();
                                setPrevGameId(json.current_game_id);
                                // setJustOpened(false);
                            } else {
                                openStatus = "closed";
                                // fetch_data_first();
                            }

                            fetch_data_first();

                            //end of start spinner
                        } else {
                            //means it is open
                            setTimeRemaining(json.time_remaining);
                            if (openStatus == "closed") {
                                //  console.log("open changed");
                                get_current_game_length();
                                // db_random_number = json.random_number;
                                // db_winner_number = json.winner_number;
                                openStatus = "open";
                                setPageState("normal");
                                // setPageState("animation");
                                // setCurrentGameId(json.current_game_id);
                                setCurrentGameId(json.current_game_id);
                                setJustOpened(true);
                                last_ten_rows();
                            }

                            // setCenterBigNumber(false);

                            if (
                                json.time_remaining <= current_game_length / 2 + 2000 &&
                                json.time_remaining >= current_game_length / 2 - 2000
                            ) {
                                slide_out();
                            }

                            openStatus = "open";

                            fetch_data_first();
                        }
                        //console.log("to fetch data second");

                        // console.log("fetched");
                    });
                } else {
                    // console.log("not fetched");
                    console.log("error 1");
                    fetch_data_first();
                }
            })
            .catch((err) => {
                // console.log("AXIOS ERROR: ", err);
                //     console.log("not fetched 2");
                console.log("error 2");
                fetch_data_first();
            });
        //---------------------------end of fetching open status and time remaining---------------------
    }

    function seconds_to_clock(duration) {
        if (duration < 0) {
            duration = 0;
        }
        var time = duration / 1000;
        // var hours = Math.floor(time / 3600);
        // time -= hours * 3600;

        var minutes = Math.floor(time / 60);
        time -= minutes * 60;

        var seconds = parseInt(time % 60, 10);

        //console.log(hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
        return (
            (minutes < 10 ? "0" + minutes : minutes) +
            ":" +
            (seconds < 10 ? "0" + seconds : seconds)
        );
    }

    function seconds_to_clock_time(duration) {
        if (duration < 0) {
            duration = 0;
        }
        var time = duration / 1000;
        // var hours = Math.floor(time / 3600);
        // time -= hours * 3600;
        return duration
        var minutes = Math.floor(time / 60);
        time -= minutes * 60;

        var seconds = parseInt(time % 60, 10);

        //console.log(hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
        return (
            (minutes < 10 ? "0" + minutes : minutes) +
            ":" +
            (seconds < 10 ? "0" + seconds : seconds)
        );
    }

    function shuffle(array) {
        const newArray = [...array];
        const length = newArray.length;

        for (let start = 0; start < length; start++) {
            const randomPosition = Math.floor(
                (newArray.length - start) * Math.random()
            );
            const randomItem = newArray.splice(randomPosition, 1);

            newArray.push(...randomItem);
        }

        return newArray;
    }

    function seventy_timer() {
        // var seventy_over = 0;
        var seventy_timer = setTimeout(function () {
            // if (seventy_over == 0) {
            //  seventy_over = 1;
            // } else {
            //
            setPageState("lasttenresults");
            //setPageState("normal");
            clearTimeout(seventy_timer);
            // }
        }, 70000);
    }
    async function play(index) {
        var audio = audioRef1.current;
        if (index == 1) audio = audioRef1.current;
        if (index == 2) audio = audioRef2.current;
        if (index == 3) audio = audioRef3.current;
        if (index == 4) audio = audioRef4.current;
        if (index == 5) audio = audioRef5.current;
        if (index == 6) audio = audioRef6.current;
        if (index == 7) audio = audioRef7.current;
        if (index == 8) audio = audioRef8.current;
        if (index == 9) audio = audioRef9.current;
        if (index == 10) audio = audioRef10.current;
        if (index == 11) audio = audioRef11.current;
        if (index == 12) audio = audioRef12.current;
        if (index == 13) audio = audioRef13.current;
        if (index == 14) audio = audioRef14.current;
        if (index == 15) audio = audioRef15.current;
        if (index == 16) audio = audioRef16.current;
        if (index == 17) audio = audioRef17.current;
        if (index == 18) audio = audioRef18.current;
        if (index == 19) audio = audioRef19.current;
        if (index == 20) audio = audioRef20.current;

        await audio.play();
    }

    async function playSoundBall(index) {
        var audio = audioRef21.current;

        if (index == 1) audio = audioRef22.current;
        if (index == 1) audio = audioRef23.current;
        if (index == 1) audio = audioRef24.current;

        await audio.play();
    }

    useEffect(() => {
        var audio = audioRefClassical.current;
        if (pageState == 'normal' || pageState == 'lasttenresults') {
            console.log('classical audio played ');
            audio.play();
        }
        else if (pageState == 'animation' || pageState == 'video') {
            console.log('classical audio pause ');
            audio.pause();
        }
    }, [pageState])

    useEffect(() => {
        if (firstTime) {
            const interval_ = setTimeout(() => {
                setFirstTime(false)
            }, 8000)
            return () => clearTimeout(interval_);
        }
    }, [firstTime])

    useEffect(() => {
        if (pickRenderTime == 12) {
            const interval_ = setTimeout(() => {
                setPickRenderTime(1)
            }, 6000)
            return () => clearTimeout(interval_);
        }
        else {
            const interval_ = setTimeout(() => {
                setPickRenderTime(pickRenderTime + 1)
            }, 6000)
            return () => clearTimeout(interval_);
        }
    }, [pickRenderTime])
    useEffect(() => {
        if (seconds_to_clock(timeRemaining) == "00:00" || timeRemaining == "00:00") {
            const interval_ = setTimeout(() => {
                setShowBetClosed(true)
            }, 0);
            return () => clearTimeout(interval_);
        }
        else {
            setShowBetClosed(false)
        }
    }, [timeRemaining])
    useEffect(() => {
        if (setOutOfTwenty == "") {
            setOutOfTwenty('1' + "/20");
        }
        else {
            const animation_count = parseInt(outOfTwenty.split('/')[0]) + 1
            if (animation_count < 21) {
                const interval_ = setTimeout(() => {
                    setOutOfTwenty(animation_count.toString() + "/20");
                    var heads = 0;
                    var tails = 0;
                    for (var i = 0; i < animation_count; i++) {
                        if (shuffle_array_state[i] * 1 > 40) {
                            tails++;
                        } else {
                            heads++;
                        }
                    }
                    if (tails == heads) {
                        setEvens("EVENS");
                    } else if (tails > heads) {
                        setEvens("TAILS");
                    } else if (heads > tails) {
                        setEvens("HEADS");
                    }

                    const interval = setTimeout(() => {
                        playSoundBall(animation_count);
                        play(animation_count);
                    }, 900)
                    return () => clearTimeout(interval);
                }, 3000)
                return () => clearTimeout(interval_);
            }

        }
    }, [outOfTwenty])

    useEffect(() => {
        if (shuffle_array_state.length && (outOfTwenty == '' || outOfTwenty == '20/20')) {
            setOutOfTwenty('1' + "/20");
            playSoundBall(1)
            play(1);
        }
    }, [shuffle_array_state])

    function start_three_seconds(shuffle_array) {
        //----------------------------set audio Number
        var animation_count = 0;
        var three_sec = setInterval(function () {
            animation_count++;
            if (animation_count > 20) {
                clearInterval(three_sec);
            } else {
                //setAudioNumber(shuffle_array[animation_count - 1] * 1);
                setOutOfTwenty(animation_count.toString() + "/20");
                var heads = 0;
                var tails = 0;
                for (var i = 0; i < animation_count; i++) {
                    if (shuffle_array[i] * 1 <= 40) {
                        tails++;
                    } else {
                        heads++;
                    }
                }
                if (tails == heads) {
                    setEvens("EVENS");
                } else if (tails > heads) {
                    setEvens("TAILS");
                } else if (heads > tails) {
                    setEvens("HEADS");
                }

                playSoundBall(animation_count)
                play(animation_count);
            }
        }, 3000);
        // return () => clearInterval(three_sec)
    }

    function start_vid_timer(shuffle_array) {
        // var v_timer = 0;
        var vid_timer = setTimeout(function () {
            //if (v_timer == 0) {
            //} else {
            //   console.log("hello I am here");
            setPageState("animation");
            setSlideIn(true);
            seventy_timer();
            setShuffleArrayState(shuffle_array);
            clearTimeout(vid_timer);

            //}
            // v_timer = 1;
        }, 8000);
    }
    const slide_in = async (shuffle_array) => {
        // when the game is closed
        setShuffledNumbers(shuffle_array);

        //---------------------------------------------------now set the twenty numbers-----------------------------------------------------
        //   setTimeout(function(){
        //     setCircleTopNumber("80");
        //     setCircleBottomNumber("30");
        // }, 3000);
        // setJustOpened(false);

        //setSlideIn(true);
    };

    function slide_out() {
        // when the game is half open
        // setSlideIn(false);
    }

    function just_opened() {
        //when the game opens
        // bring in the stats
        // setJustOpened(true);
    }

    // alert("hello");

    useEffect(() => {
        fetch_data_intro();
    }, []);

    const gradientStyle = {
        background: "linear-gradient(to bottom, #FFFC14, #8E8F17)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontSize: "50px",
        fontWeight: "bold",
        // fontFamily: "Arial, sans-serif",
        fontFamily: "Rationell ExtraBlack",
        // textShadow: "0px 4px 4px rgba(0,0,0,0.4)",
        textShadow: 'rgb(225 178 35 / 44%) 3px 2px 1px',

        // background:
        //   "-webkit-linear-gradient(#fbea00, #a68430)" /* For Safari 5.1 to 6.0 */,
        // WebkitBackgroundClip: "text",
        // fontWeight: 900,
        // WebkitTextFillColor: "transparent",
    };

    const remainingTimeStyle = {
        background: "linear-gradient(to bottom, #fff361, #a27f2c)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontSize: "60px",
        fontWeight: "bold",
        fontFamily: "waukegan",
        textShadow: "0px 4px 4px rgba(0,0,0,0.4)",
        marginTop: '-50px',
    };
    return (
        <div className="dv_main_container">
            {firstTime && (<img style={{ width: '100vw', height: '100vh' }} src={require("../../resources/Keno/img/first-time.JPG")} />)
            }
            {/* <img src={eighty_img} alt={"img"}/> */}
            {pageState == "normal" && !firstTime && (
                <div className="dv_internal_container">

                    {/* <div className="dv_numbers_container">
          <div className = {animationClass ? "dv_bg_test dv_bg_test_animated" : "dv_bg_test"}>Hello</div>
          <button onClick = {() => setAnimationClass(true)}>Change bg after 5 seconds</button>
          <button onClick = {() => setAnimationClass(false)}>restore bg</button>

            
          </div> */}
                    {/* onTransitionEnd = {slideIn == false && transitionEnd} */}
                    <div className="dv_number_grid_container">
                        <div className="dv_time_remaining">
                          {/* <span className="spn_draw">
                                <span style={{
                                    fontFamily: "DDT W00 Expanded Heavy",
                                    fontWeight: '900%',
                                    letterSpacing: "-6px",
                                    fontSize: '85px',
                                    marginTop: '20px',
                                    marginLeft: '-10px',
                                    marginLeft: '5px',
                                    background: '-webkit-linear-gradient(yellow,yellow,#B09709,yellow,yellow,yellow,yellow,#B09709,black,#CBB901,black,black)',
                                    '-webkit-text-fill-color': 'transparent',
                                    '-webkit-background-clip': 'text',


                                }}>DRAW </span>*/}
                                  <img src={draw_img1} style={{
                                        height: '70PX',
                                        width: '300PX'
                                    }} />
                                {/*}   <span className="dv_time_remaining_game_number" style={{ 
                                    color: "rgb(240, 225, 12)", fontSize: "70px" , fontFamily: "DDT W00 Expanded Heavy"
                                    }}>
                                    DRAW
                                </span> */}
                                &nbsp;
                                <span className="dv_time_remaining_game_number" style={{
                                    color: "white", marginLeft: "-380px", marginTop: "2px", letterSpacing: "-1px", fontSize: "70px", fontFamily: "DDT W00 Expanded Heavy"
                                }}>
                                    {prevGameId}
                                </span>
                          {/* Baze Span  </span>*/}
                            {evens && (evens == 'HEADS' || evens == 'EVENS') && <span className="spn_evens" style={evens == 'HEADS' ? { backgroundImage: 'linear-gradient(#F2D80F,#CDB80F,#CDB80F, #F2D80F)', width: '250px', textAlign: "center" } : {}}>{evens}</span>}
                            {(!evens || evens == 'TAILS') && <span className="spn_evens" style={{ backgroundImage: 'linear-gradient(#B81300, #B81300)', color: '#B81300', width: '250px', textAlign: "center" }}>None&nbsp;&nbsp;</span>}
                        </div>
                        <div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("1")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    1
                                </div>


                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("2")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    2
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("3")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    3
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("4")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    4
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("5")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    5
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("6")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    6
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("7")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    7
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("8")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    8
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("9")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    9
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("10")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    10
                                </div>
                            </div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("11")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    11
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("12")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    12
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("13")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    13
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("14")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    14
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("15")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    15
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("16")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    16
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("17")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    17
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("18")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    18
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("19")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    19
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("20")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    20
                                </div>
                            </div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("21")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    21
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("22")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    22
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("23")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    23
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("24")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    24
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("25")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    25
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("26")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    26
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("27")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    27
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("28")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    28
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("29")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    29
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("30")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    30
                                </div>
                            </div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("31")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    31
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("32")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    32
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("33")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    33
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("34")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    34
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("35")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    35
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("36")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    36
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("37")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    37
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("38")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    38
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("39")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    39
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("40")
                                                ? "dv_grid_cells_selected"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    40
                                </div>
                            </div>
                            {/* </div>

          <div style={{ border: "2px solid gold" }}> */}

                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("41")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("41")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    41
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("42")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("42")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    42
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("43")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("43")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    43
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("44")
                                                ? grayGridClasses[shuffledNumbers.indexOf("44")]
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    44
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("45")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("45")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    45
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("46")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("46")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    46
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("47")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("47")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    47
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("48")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("48")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    48
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("49")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("49")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    49
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("50")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("50")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    50
                                </div>
                            </div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("51")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("51")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    51
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("52")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("52")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    52
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("53")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("53")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    53
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("54")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("54")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    54
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("55")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("55")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    55
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("56")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("56")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    56
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("57")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("57")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    57
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("58")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("58")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    58
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("59")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("59")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    59
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("60")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("60")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    60
                                </div>
                            </div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("61")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("61")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    61
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("62")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("62")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    62
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("63")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("63")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    63
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("64")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("64")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    64
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("65")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("65")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    65
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("66")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("66")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    66
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("67")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("67")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    67
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("68")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("68")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    68
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("69")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("69")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    69
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("70")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("70")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    70
                                </div>
                            </div>
                            <div className="dv_row">
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("71")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("71")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    71
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("72")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("72")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    72
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("73")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("73")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    73
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("74")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("74")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    74
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("75")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("75")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    75
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("76")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("76")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    76
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("77")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("77")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    77
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("78")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("78")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    78
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("79")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("79")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    79
                                </div>
                                <div
                                    className={
                                        slideIn
                                            ? shuffledNumbers.includes("80")
                                                // ? grayGridClasses[shuffledNumbers.indexOf("80")]
                                                ? "dv_grid_gray_cells_animated"
                                                : "dv_grid_cells_normal"
                                            : "dv_grid_cells_normal"
                                    }
                                >
                                    80
                                </div>
                            </div>
                        </div>
                        <div className="dv_evens">
                            <div>
                                <img src={keno_logo} className="img_keno_logo" />
                            </div>
                            <span className="spn_draw"></span>
                            {/* <span className="spn_evens">{evens}</span> */}
                            {evens && (evens == 'TAILS' || evens == 'EVENS') && <span className="spn_evens" style={evens == 'TAILS' ? { backgroundImage: 'linear-gradient(yellow,#CDB80F,#CDB80F, yellow)', width: '250px', textAlign: "center" } : {}}>{evens}</span>}
                            {(!evens || evens == 'HEADS') && <span className="spn_evens" style={{ backgroundImage: 'linear-gradient(#B81300, #B81300)', color: '#B81300', width: '250px', textAlign: "center" }}>None&nbsp;&nbsp;</span>}
                        </div>

                        {/* <div className = "dv_time_remaining">
               <span className = "spn_time_main">TIME REMAINING </span>&nbsp;&nbsp;<span className = "spn_time_main">  {timeRemaining == "- : -" ? timeRemaining : seconds_to_clock(timeRemaining)} </span>
               </div> */}
                    </div>
                    <div className="dv_circles_container">
                        <div className="dv_current_game" style={{ marginTop: '-60px' }}>
                         {/*}   <span style={{
                                fontFamily: "DDT W00 Expanded Heavy",
                                fontWeight: 'bolder',
                                letterSpacing: "-7px",
                                fontSize: '70px',
                                marginTop: '55px',
                                marginLeft: '-5px',

                                background: '-webkit-linear-gradient(yellow,yellow,yellow,yellow,#CBB901,black,black)',
                                '-webkit-text-fill-color': 'transparent',
                                '-webkit-background-clip': 'text',
                            }}>DRAW</span>*/}
                               <img src={draw_img3} style={{
                                        height: '70PX',
                                        marginTop: '40PX',
                                        marginLeft: '-5PX',
                                        width: '300PX'
                                    }} />
                            &nbsp;
                            <span className="dv_time_remaining_game_number" style={{ fontFamily: "DDT W00 Expanded Heavy", paddingTop: '40px', letterSpacing: "-4px", fontSize: '70px', marginLeft: '-10PX', marginTop: "5px" }}>{currentGameId}</span>
                        </div>
                        <span
              /* className="spn_time_remaining" */ style={remainingTimeStyle}
                        >
                            {!showBetClosed && <span className={timeRemaining < 10000 ? "spn_time_main2 spn_time_main2_blink" : "spn_time_main2"} style={{
                                fontWeight: 'bolder',
                                fontSize: '130px',
                                textShadow: "0 0 1px #FFD700,0 0 26px black",
                                background: '-webkit-linear-gradient(yellow,yellow,#FBEB00,#FFEA00,#FFE900,#FEEA00,#BFA60E,#BFA60E)',
                                '-webkit-text-fill-color': 'transparent',
                                '-webkit-background-clip': 'text',
                                marginTop: '90px',
                            }}>
                                {" "}
                                {timeRemaining == "00:00"
                                    ? timeRemaining
                                    : seconds_to_clock(timeRemaining) == "00:00"
                                        ? showBetClosed ? "BETS CLOSED" : "00:00"
                                        : seconds_to_clock(timeRemaining)}{" "}
                            </span>}
                            {showBetClosed && <span className={timeRemaining < 10000 ? "spn_time_main2 spn_time_main2_blink" : "spn_time_main2"} style={{
                                fontWeight: 'bolder',
                                fontSize: '70px',
                                textShadow: "0 0 1px #FFD700,0 0 26px black",
                                background: '-webkit-linear-gradient(yellow,yellow,#FBEB00,#FFEA00,#FFE900,#FEEA00,#BFA60E,#BFA60E)',
                                '-webkit-text-fill-color': 'transparent',
                                '-webkit-background-clip': 'text',
                                marginTop: '90px',
                            }}>
                                {"BETS CLOSED"}
                            </span>}
                        </span>
                        <div className="flip-card">
                            <div
                                className="flip-card-inner"
                                style={{
                                    // transform: justOpened ? "rotateY(180deg)" : "rotateY(0deg)",
                                    transform: justOpened ? "rotateY(180deg)" : "rotateY(180deg)",
                                }}
                            >
                                <div className="flip-card-front">
                                    <div
                                        className="dv_golden_circle"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <span className="spn_golden_circle">{circleTopNumber}</span>
                                    </div>
                                    {/* <div className="dv_golden_circle">
                  <span className="spn_golden_circle">
                    {circleBottomNumber}
                  </span>
                </div> */}
                                </div>
                                <div className="flip-card-back">
                                    {/* <h1>John Doe</h1> 
      <p>Architect & Engineer</p> 
      <p>We love that guy</p> */}
                                    <div className="image-wrap">
                                        <div className="images">
                                            {pickRenderTime == 1 && (<div className="dv_stat_container">
                                                <div className="dv_pick_10_info">
                                                    <div style={{ fontSize: '60px', marginTop: '-10px' }} className="dv_pick_10_info_red">PICK 3</div>
                                                    <div style={{ fontSize: '60px', marginTop: '-10px' }}>To</div>
                                                    <div style={{ fontSize: '60px', marginTop: '-15px' }} className="dv_pick_10_info_red">PICK 10</div>
                                                    <div style={{ fontSize: '42px', marginTop: '-10px' }}>games have</div>
                                                    <div className="dv_pick_10_info_row" style={{ fontSize: '60px', marginTop: '-10px' }}><div className="dv_pick_10_info_red" style={{ color: 'yellow' }}>MULTIPLE</div></div>
                                                    <div className="dv_pick_10_info_row" style={{ fontSize: '60px', marginTop: '-10px' }}><div className="dv_pick_10_info_red" style={{ color: 'yellow' }}>PLAY LEVES</div></div>
                                                    <div style={{ fontSize: '42px', marginTop: '-10px' }}>on other spots.</div>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 2 && (<div className="dv_stat_container">
                                                <div className="dv_pick_10_info">
                                                    <div>Play</div>
                                                    <div className="dv_pick_10_info_row">The&nbsp;<div className="dv_pick_10_info_red">PICK 10</div>&nbsp;Games</div>

                                                    <div style={{ fontSize: '42px', marginTop: '40px' }} className="dv_pick_10_info_row">Get&nbsp;<div className="dv_pick_10_info_red">10</div>&nbsp;Numbers</div>
                                                    <div style={{ fontSize: '42px' }}>correct, and</div>
                                                    <div style={{ fontSize: '42px' }}>win the</div>
                                                    <div className="dv_pick_10_info_row"><div className="dv_pick_10_info_red">PICK 10</div>&nbsp;JacKPOT</div>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 3 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container" style={{ width: '390px' }}>
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={1} className="th_picks" style={{ paddingLeft: '5%' }}>PICK 10</th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>10</td>
                                                                <td>5000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>2500</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>400</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>40</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>12</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>4</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>2</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 4 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 9
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>4200</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>1800</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>120</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>18</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>6</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>3</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}

                                            {pickRenderTime == 5 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 8
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>3000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>600</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>68</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>4</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}

                                            {pickRenderTime == 6 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 7
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>2150</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>120</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>12</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>6</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 7 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 6
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>1800</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>70</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>10</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 8 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 5
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>300</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>3</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 9 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 4
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>100</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 10 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 3
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>35</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>3</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 11 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 2
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>15</td>
                                                            </tr>
                                                            {/*} <tr>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>*/}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                            {pickRenderTime == 12 && (<div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 1
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>3.80</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>)}
                                        </div>
                                        {/* <div className="images">
                                            {seconds_to_clock_time(timeRemaining) > 27500 && (
                                            <div className="dv_stat_container">
                                                <div className="dv_pick_10_info">
                                                    <div style={{fontSize: '60px', marginTop: '-10px'}} className="dv_pick_10_info_red">PICK 3</div>
                                                    <div style={{fontSize: '60px', marginTop: '-10px'}}>To</div>
                                                    <div style={{fontSize: '60px', marginTop: '-15px'}} className="dv_pick_10_info_red">PICK 10</div>
                                                    <div style={{fontSize: '42px', marginTop: '-10px'}}>games have</div>
                                                    <div className="dv_pick_10_info_row" style={{fontSize: '60px', marginTop: '-10px'}}><div className="dv_pick_10_info_red" style={{color: 'yellow'}}>MULTIPLE</div></div>
                                                    <div className="dv_pick_10_info_row" style={{fontSize: '60px', marginTop: '-10px'}}><div className="dv_pick_10_info_red" style={{color: 'yellow'}}>PLAY LEVES</div></div>
                                                    <div style={{fontSize: '42px', marginTop: '-10px'}}>on other spots.</div>                                                    
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 25000 && seconds_to_clock_time(timeRemaining) < 27501 && (
                                            <div className="dv_stat_container">
                                                <div className="dv_pick_10_info">
                                                    <div>Play</div>
                                                    <div className="dv_pick_10_info_row">The&nbsp;<div className="dv_pick_10_info_red">PICK 10</div>&nbsp;Games</div>
                                            
                                                    <div style={{fontSize: '42px', marginTop: '40px'}} className="dv_pick_10_info_row">Get&nbsp;<div className="dv_pick_10_info_red">10</div>&nbsp;Numbers</div>
                                                    <div style={{fontSize: '42px'}}>correct, and</div>
                                                    <div style={{fontSize: '42px'}}>win the</div>
                                                    <div className="dv_pick_10_info_row"><div className="dv_pick_10_info_red">PICK 10</div>&nbsp;JacKPOT</div>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 22500 &&  seconds_to_clock_time(timeRemaining) < 25001 (
                                            <div className="dv_stat_container" style={{marginTop: '-40px'}}>
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK <span>10</span>
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>10</td>
                                                                <td>7000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>5000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>3000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>200</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>5</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 20000 && seconds_to_clock_time(timeRemaining) < 22501  (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK &nbsp; <span>9</span>
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>5000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>3000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>200</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>5</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 17500 && seconds_to_clock_time(timeRemaining) < 20001  (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK &nbsp; <span>8</span>
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>3000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>200</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>5</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 15000 && seconds_to_clock_time(timeRemaining) < 17501 (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 7
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>2000</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>150</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>20</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>5</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 12500 && seconds_to_clock_time(timeRemaining) < 15001 (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 6
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>1800</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>70</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>10</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 10000 && seconds_to_clock_time(timeRemaining) < 12501 (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 5
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>300</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>3</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 7500 && seconds_to_clock_time(timeRemaining) < 10001 (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 4
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>100</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) > 5000 && seconds_to_clock_time(timeRemaining) < 7501 (                                            
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 3
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>3</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}

                                            {seconds_to_clock_time(timeRemaining) > 2500 && seconds_to_clock_time(timeRemaining) < 5001 (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 2
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {seconds_to_clock_time(timeRemaining) >= 0 && seconds_to_clock_time(timeRemaining) < 2501 (
                                            <div className="dv_stat_container">
                                                <div className="dv_tbl_container">
                                                    <table className="tbl_stats">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2} className="th_picks">
                                                                    PICK 1
                                                                </th>
                                                            </tr>
                                                            <tr className="tr_hits_win">
                                                                <th>HITS</th>
                                                                <th>WIN</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>3</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className="image-wrap2">
        <div className="images2">
          {lastTenDraws.map((draw, index) => {
            return (
              <div className="dv_stat_container2">
                <div className="dv_tbl_container2">
                  <table className="tbl_stats2">
                    <tr>
                      <th>DRAW {draw} RESULTS : </th>
                      <th>
                        {lastTenWinners[index].map((winner) => {
                          return (
                            <div className="spn_result_numbers">{winner}</div>
                          );
                        })}
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}

            {pageState == "video" && !firstTime && (
                <div className="dv_balls_video">
                    {/* <button
            onClick={() => setPageState("animation")}
            style={{
              zIndex: 1000,
              position: "fixed",
              top: "20px",
              left: "20px",
            }}
          >
            page change
          </button> */}
                    <video
                        src={videoBg}
                        autoPlay={true}
                        muted={false}
                        // muted
                        id="vid_bg"
                        style={{
                            width: "80vw",
                            height: "80vh",
                            opacity: 1,
                            objectFit: "fill",
                            position: "fixed",
                            top: 0,
                            left: 0,
                        }}
                    />
                </div>
            )}

            {pageState == "animation" && !firstTime && (
                <div className="dv_bottle_bg">
                    <div className="dv_out_of_twenty">

                        <div>{outOfTwenty.split('/')[0]}</div>
                        <div style={{ marginLeft: '30px', marginTop: '-40px' }}>{'/'}</div>
                        <div style={{ marginLeft: '65px', marginTop: '-50px' }}>{outOfTwenty.split('/')[1]}</div>
                    </div>
                    <div className="dv_animation_full_screen">
                        {/* <button onClick={() => setSlideIn(true)}>Animate</button>
            <button onClick={() => setSlideIn(false)}>Normal</button>
            <button onClick={() => setPageState("video")}>page change</button>
            <span>{window.screen.width}</span> */}
                        <div className="dv_grid_animation">
                            <div style={{ height: "20px" }}></div>
                            <div className="dv_time_remaining">
                                <span className="spn_draw">
                                 {/*}   <span style={{
                                        fontFamily: "DDT W00 Expanded Heavy",
                                        fontWeight: '900',
                                        letterSpacing: "-6px",
                                        fontSize: '90px',
                                        marginTop: '20px',
                                        marginLeft: '-10px',
                                        marginLeft: '5px',
                                        background: '-webkit-linear-gradient(yellow,yellow,yellow,yellow,yellow,yellow,#B09709,#CBB901,black,#BBA501,black)',
                                        '-webkit-text-fill-color': 'transparent',
                                        '-webkit-background-clip': 'text',
                                    }}>DRAW </span>*/}
                                     <img src={draw_img2} style={{
                                        height: '70PX',
                                        width: '300PX'
                                    }} />
                                    &nbsp;
                                    <span style={{ color: "white", fontSize: "70px", fontFamily: "DDT W00 Expanded Heavy" }}>
                                        {prevGameId}
                                    </span>
                                </span>

                                {evens && (evens == 'HEADS' || evens == 'EVENS') && <span className="spn_evens" style={evens == 'HEADS' ? { backgroundImage: 'linear-gradient(#F2D80F,#CDB80F,#CDB80F,#F2D80F)', width: '250px', textAlign: "center" } : {}}>{evens ? evens : " "}</span>}
                                {(!evens || evens == 'TAILS') && <span className="spn_evens" style={{ backgroundImage: 'linear-gradient(#B81300, #B81300)', color: '#B81300', width: '250px', textAlign: "center" }}>None&nbsp;&nbsp;</span>}
                            </div>
                            <div>
                                <div className="dv_row">
                                    <div
                                        // style={
                                        //   slideIn && {
                                        //     backgroundImage: `linear-gradient(yellow, rgb(200, 200, 77))`,
                                        //     transition: "background-image 0.5s ease",
                                        //   }
                                        // }
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("1")
                                                    ? gridClasses[shuffledNumbers.indexOf("1")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        1
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("2")
                                                    ? gridClasses[shuffledNumbers.indexOf("2")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        2
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("3")
                                                    ? gridClasses[shuffledNumbers.indexOf("3")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        3
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("4")
                                                    ? gridClasses[shuffledNumbers.indexOf("4")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        4
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("5")
                                                    ? gridClasses[shuffledNumbers.indexOf("5")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        5
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("6")
                                                    ? gridClasses[shuffledNumbers.indexOf("6")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        6
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("7")
                                                    ? gridClasses[shuffledNumbers.indexOf("7")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        7
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("8")
                                                    ? gridClasses[shuffledNumbers.indexOf("8")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        8
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("9")
                                                    ? gridClasses[shuffledNumbers.indexOf("9")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        9
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("10")
                                                    ? gridClasses[shuffledNumbers.indexOf("10")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        10
                                    </div>
                                </div>
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("11")
                                                    ? gridClasses[shuffledNumbers.indexOf("11")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        11
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("12")
                                                    ? gridClasses[shuffledNumbers.indexOf("12")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        12
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("13")
                                                    ? gridClasses[shuffledNumbers.indexOf("13")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        13
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("14")
                                                    ? gridClasses[shuffledNumbers.indexOf("14")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        14
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("15")
                                                    ? gridClasses[shuffledNumbers.indexOf("15")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        15
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("16")
                                                    ? gridClasses[shuffledNumbers.indexOf("16")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        16
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("17")
                                                    ? gridClasses[shuffledNumbers.indexOf("17")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        17
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("18")
                                                    ? gridClasses[shuffledNumbers.indexOf("18")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        18
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("19")
                                                    ? gridClasses[shuffledNumbers.indexOf("19")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        19
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("20")
                                                    ? gridClasses[shuffledNumbers.indexOf("20")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        20
                                    </div>
                                </div>
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("21")
                                                    ? gridClasses[shuffledNumbers.indexOf("21")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        21
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("22")
                                                    ? gridClasses[shuffledNumbers.indexOf("22")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        22
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("23")
                                                    ? gridClasses[shuffledNumbers.indexOf("23")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        23
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("24")
                                                    ? gridClasses[shuffledNumbers.indexOf("24")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        24
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("25")
                                                    ? gridClasses[shuffledNumbers.indexOf("25")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        25
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("26")
                                                    ? gridClasses[shuffledNumbers.indexOf("26")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        26
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("27")
                                                    ? gridClasses[shuffledNumbers.indexOf("27")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        27
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("28")
                                                    ? gridClasses[shuffledNumbers.indexOf("28")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        28
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("29")
                                                    ? gridClasses[shuffledNumbers.indexOf("29")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        29
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("30")
                                                    ? gridClasses[shuffledNumbers.indexOf("30")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        30
                                    </div>
                                </div>
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("31")
                                                    ? gridClasses[shuffledNumbers.indexOf("31")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        31
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("32")
                                                    ? gridClasses[shuffledNumbers.indexOf("32")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        32
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("33")
                                                    ? gridClasses[shuffledNumbers.indexOf("33")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        33
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("34")
                                                    ? gridClasses[shuffledNumbers.indexOf("34")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        34
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("35")
                                                    ? gridClasses[shuffledNumbers.indexOf("35")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        35
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("36")
                                                    ? gridClasses[shuffledNumbers.indexOf("36")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        36
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("37")
                                                    ? gridClasses[shuffledNumbers.indexOf("37")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        37
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("38")
                                                    ? gridClasses[shuffledNumbers.indexOf("38")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        38
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("39")
                                                    ? gridClasses[shuffledNumbers.indexOf("39")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        39
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("40")
                                                    ? gridClasses[shuffledNumbers.indexOf("40")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        40
                                    </div>
                                </div>
                                {/* </div>

          <div style={{ border: "2px solid gold" }}> */}
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("41")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("41")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        41
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("42")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("42")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        42
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("43")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("43")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        43
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("44")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("44")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        44
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("45")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("45")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        45
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("46")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("46")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        46
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("47")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("47")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        47
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("48")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("48")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        48
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("49")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("49")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        49
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("50")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("50")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        50
                                    </div>
                                </div>
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("51")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("51")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        51
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("52")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("52")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        52
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("53")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("53")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        53
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("54")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("54")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        54
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("55")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("55")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        55
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("56")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("56")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        56
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("57")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("57")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        57
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("58")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("58")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        58
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("59")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("59")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        59
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("60")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("60")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        60
                                    </div>
                                </div>
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("61")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("61")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        61
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("62")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("62")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        62
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("63")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("63")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        63
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("64")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("64")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        64
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("65")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("65")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        65
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("66")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("66")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        66
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("67")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("67")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        67
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("68")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("68")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        68
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("69")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("69")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        69
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("70")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("70")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        70
                                    </div>
                                </div>
                                <div className="dv_row">
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("71")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("71")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        71
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("72")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("72")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        72
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("73")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("73")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        73
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("74")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("74")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        74
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("75")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("75")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        75
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("76")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("76")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        76
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("77")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("77")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        77
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("78")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("78")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        78
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("79")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("79")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        79
                                    </div>
                                    <div
                                        className={
                                            slideIn
                                                ? shuffledNumbers.includes("80")
                                                    ? grayGridClasses[shuffledNumbers.indexOf("80")]
                                                    : "dv_grid_cells_normal"
                                                : "dv_grid_cells_normal"
                                        }
                                    >
                                        80
                                    </div>
                                </div>
                            </div>
                            <div className="dv_evens">
                                <div>
                                    <img src={keno_logo} className="img_keno_logo" />
                                </div>
                                <span className="spn_draw"></span>
                                {/* <span className="spn_evens">{evens}</span> */}
                                {evens && (evens == 'TAILS' || evens == 'EVENS') && <span className="spn_evens" style={evens == 'TAILS' ? { backgroundImage: 'linear-gradient(#F2D80F,#CDB80F,#CDB80F, #F2D80F)', width: '250px', textAlign: "center" } : {}}>{evens}</span>}
                                {(!evens || evens == 'HEADS') && <span className="spn_evens" style={{ backgroundImage: 'linear-gradient(#B81300, #B81300)', color: '#B81300', width: '250px', textAlign: "center" }}>None&nbsp;&nbsp;</span>}
                            </div>
                        </div>
                        {/* {<div className="dv_number_grid_contain" style={{color: 'white', fontFamily: "DDT W00 Expanded Heavy"}}>jdlsj</div> } */}
                        <img className="img_bottle" src={img_bottle} alt="Logo" />
                    </div>
                    <div></div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated1" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[0] > 0 ? imgs[shuffledNumbers[0]] : imgs[1]} alt="Logo"
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated2" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[1] > 0 ? imgs[shuffledNumbers[1]] : imgs[2]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated3" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[2] > 0 ? imgs[shuffledNumbers[2]] : imgs[3]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated4" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[3] > 0 ? imgs[shuffledNumbers[3]] : imgs[4]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated5" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[4] > 0 ? imgs[shuffledNumbers[4]] : imgs[5]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated6" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[5] > 0 ? imgs[shuffledNumbers[5]] : imgs[6]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated7" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[6] > 0 ? imgs[shuffledNumbers[6]] : imgs[7]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated8" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[7] > 0 ? imgs[shuffledNumbers[7]] : imgs[8]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn ? "dv_balls_normal dv_balls_animated9" : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[8] > 0 ? imgs[shuffledNumbers[8]] : imgs[9]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated10"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={shuffledNumbers[9] > 0 ? imgs[shuffledNumbers[9]] : imgs[10]}
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated11"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[10] > 0 ? imgs[shuffledNumbers[10]] : imgs[11]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated12"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[11] > 0 ? imgs[shuffledNumbers[11]] : imgs[12]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated13"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[12] > 0 ? imgs[shuffledNumbers[12]] : imgs[13]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated14"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[13] > 0 ? imgs[shuffledNumbers[13]] : imgs[14]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated15"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[14] > 0 ? imgs[shuffledNumbers[14]] : imgs[15]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated16"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[15] > 0 ? imgs[shuffledNumbers[15]] : imgs[16]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated17"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[16] > 0 ? imgs[shuffledNumbers[16]] : imgs[17]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated18"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[17] > 0 ? imgs[shuffledNumbers[17]] : imgs[18]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated19"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[18] > 0 ? imgs[shuffledNumbers[18]] : imgs[19]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                    <div
                        className={
                            slideIn
                                ? "dv_balls_normal dv_balls_animated20"
                                : "dv_balls_normal"
                        }
                    >
                        <img
                            src={
                                shuffledNumbers[19] > 0 ? imgs[shuffledNumbers[19]] : imgs[20]
                            }
                            className="img-shuffled-number-ball"
                        />
                    </div>
                </div>
            )}
            {(pageState == "lasttenresults") && (
                <div className="dv_last_ten_draws">
                    <div style={{ minHeight: '2px', marginRight: '5px', backgroundColor: '#C88268', width: '90%', marginLeft: '70px' }}></div>
                    {lastTenDraws.map((draw, index) => {
                        return (
                            <div className="dv_last_ten_row">
                                <div style={{ display: 'flex' }}>
                                    <div className="dv_last_ten_number">{draw}</div>
                                    <div className="dv_last_ten_only_numbers">
                                        {lastTenWinners[index].slice(0, Math.ceil(lastTenWinners[index].length / 2)).map((winner, winnerIndex) => {
                                            if (winner)
                                                return (
                                                    <div
                                                        className={
                                                            index == 9
                                                                ? winner * 1 <= 40
                                                                    ? "dv_last_ten_heads"
                                                                    : "dv_last_ten_heads_gray"
                                                                : winner * 1 <= 40
                                                                    ? "dv_last_ten_heads"
                                                                    : "dv_last_ten_heads_gray"
                                                        }
                                                    >
                                                        {winner}
                                                    </div>
                                                );
                                        })}
                                        <div style={{ border: '2px solid #C88268', minHeight: '85px', marginRight: '5px', backgroundColor: '#C88268', width: '1px' }}></div>
                                        {lastTenWinners[index].slice(Math.ceil(lastTenWinners[index].length / 2), lastTenWinners[index].length).map((winner, winnerIndex) => {
                                            return (
                                                <div
                                                    className={
                                                        index == 9
                                                            ? winner * 1 <= 40
                                                                ? "dv_last_ten_heads"
                                                                : "dv_last_ten_heads_gray"
                                                            : winner * 1 <= 40
                                                                ? "dv_last_ten_heads"
                                                                : "dv_last_ten_heads_gray"
                                                    }
                                                >
                                                    {winner}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div style={{ minHeight: '2px', marginRight: '5px', backgroundColor: '#C88268', width: '90%', marginLeft: '70px' }}></div>
                            </div>
                        );
                    })}
                </div>
            )}
            {/* <div className="dv_marquee">
        <marquee> */}
            {/* {
          lastTenDraws.map((draw, index) => {
           return <span><span className = "spn_game_result">DRAW {draw} RESULTS : </span>
           {lastTenWinners[index].map((winner) => {
            return <span className = "spn_result_numbers" >{winner}</span> ;
           })}
           </span>;
          })
         } */}
            {/* {lastTenDraws.map((draw, index) => {
            return (
              <span>
                <span>
                  <span className="spn_game_result">
                    DRAW {draw} RESULTS :{" "}
                  </span>
                  {lastTenWinners[index].map((winner) => {
                    return <div className="spn_result_numbers">{winner}</div>;
                    // return (
                    //   <img
                    //     src={imgs[winner]}
                    //     style={{ height: "30px", width: "30px" }}
                    //   />
                    // );
                  })} */}
            {/* </span>
                &nbsp;
              </span>
            );
          })}
        </marquee>
      </div> */}
            {/* <div
        className="dv_jackpot"
        style={{
          position: "fixed",
          zIndex: "200",
          background: "transparent",
          color: "white",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: jackPotIn
            ? "transform 3s ease-out"
            : "transform 6s linear",
          transform: jackPotIn ? "scale(1)" : "scale(0)",
          transitionDelay: jackPotIn ? "60s" : "0s",
        }}
      >
        <h1 style={{ fontSize: "200px" }}>Jack Pot !</h1>
      </div> */}
            <audio
                id="audioClassical"
                ref={audioRefClassical}
                src={require('../../resources/Keno/audio_numbers/Track2.wav')}
                autoPlay={true}
                muted={false}
                loop={true}
            ></audio>
            {shuffledNumbers.length == 20 && (
                <>
                    <audio
                        id="audio1"
                        ref={audioRef1}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[0]}.wav`)}
                    ></audio>
                    <audio
                        id="audio2"
                        ref={audioRef2}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[1]}.wav`)}
                    ></audio>
                    <audio
                        id="audio3"
                        ref={audioRef3}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[2]}.wav`)}
                    ></audio>
                    <audio
                        id="audio4"
                        ref={audioRef4}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[3]}.wav`)}
                    ></audio>
                    <audio
                        id="audio5"
                        ref={audioRef5}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[4]}.wav`)}
                    ></audio>
                    <audio
                        id="audio6"
                        ref={audioRef6}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[5]}.wav`)}
                    ></audio>
                    <audio
                        id="audio7"
                        ref={audioRef7}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[6]}.wav`)}
                    ></audio>
                    <audio
                        id="audio8"
                        ref={audioRef8}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[7]}.wav`)}
                    ></audio>
                    <audio
                        id="audio9"
                        ref={audioRef9}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[8]}.wav`)}
                    ></audio>
                    <audio
                        id="audio10"
                        ref={audioRef10}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[9]}.wav`)}
                    ></audio>
                    <audio
                        id="audio11"
                        ref={audioRef11}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[10]}.wav`)}
                    ></audio>
                    <audio
                        id="audio12"
                        ref={audioRef12}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[11]}.wav`)}
                    ></audio>
                    <audio
                        id="audio13"
                        ref={audioRef13}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[12]}.wav`)}
                    ></audio>
                    <audio
                        id="audio14"
                        ref={audioRef14}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[13]}.wav`)}
                    ></audio>
                    <audio
                        id="audio15"
                        ref={audioRef15}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[14]}.wav`)}
                    ></audio>
                    <audio
                        id="audio16"
                        ref={audioRef16}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[15]}.wav`)}
                    ></audio>
                    <audio
                        id="audio17"
                        ref={audioRef17}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[16]}.wav`)}
                    ></audio>
                    <audio
                        id="audio18"
                        ref={audioRef18}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[17]}.wav`)}
                    ></audio>
                    <audio
                        id="audio19"
                        ref={audioRef19}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[18]}.wav`)}
                    ></audio>
                    <audio
                        id="audio20"
                        ref={audioRef20}
                        src={require(`../../resources/Keno/audio_numbers/${shuffledNumbers[19]}.wav`)}
                    ></audio>
                    {/* //ball sound */}
                    <audio
                        id="audio21"
                        ref={audioRef21}
                        src={require(`../../resources/Keno/audio_numbers/BallSound.wav`)}
                    ></audio>
                    <audio
                        id="audio21"
                        ref={audioRef22}
                        src={require(`../../resources/Keno/audio_numbers/BallSound.wav`)}
                    ></audio>

                    <audio
                        id="audio21"
                        ref={audioRef23}
                        src={require(`../../resources/Keno/audio_numbers/BallSound.wav`)}
                    ></audio>

                    <audio
                        id="audio21"
                        ref={audioRef24}
                        src={require(`../../resources/Keno/audio_numbers/BallSound.wav`)}
                    ></audio>


                </>
            )}
        </div>
    );
};

export default TheGame;
